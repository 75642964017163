/*** IMPORTS FROM imports-loader ***/
var define = false;

$(document).ready(function(){
	
	$( "#navbarNav li" ).each(function( index ) {
		
		$(this).find('a').css('background-color',$(this).data('color'));
		
		$(this).find('a').hover(function(){
			$(this).css('color',$(this).parent().data('color'));
			$(this).css('background-color','white');
			$(this).css('border-color',$(this).parent().data('color'));
		});
		$(this).find('a').mouseout(function(){
			$(this).css('color','white');
			$(this).css('background-color',$(this).parent().data('color'));
			$(this).css('border-color','white');
		});
		
	});
	
	
	
	/* ANIMATION */
	function appear_blocks(){

		if($(window).scrollTop()>0){
			$('body').removeClass('page-template-home');
		} else if($('body').hasClass('home')){
			$('body').addClass('page-template-home');
		}
		
	}
	appear_blocks();
	$(window).scroll(function(){
		appear_blocks();
    });
	
	
	
});

